.serviceBox {
    text-align: center;
    margin: 20px 0 30px 0;
  }
  .serviceBox .service-image > a > img {
    width: 70px;
    height: auto;
    border-radius: 50%;
    transition: all 0.2s ease-in-out 0s;
  }
  .service-content {
    padding-top: 5px;
  }
  .serviceBox .service-content h3 {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1.25px;
    margin-bottom: 16px;
    color: #373e44;
    transition: all 0.21s ease 0s;
  }
  .serviceBox .service-content p {
    padding: 0 35px;
    color: #616161;
    line-height: 1.7;
  }
  .serviceBox .read {
    margin-top: 20px;
  }
  .serviceBox .read > a {
    text-transform: uppercase;
    color: #dc005a;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    position: relative;
    transition: all 0.16s ease 0s;
  }
  .serviceBox .read > a:hover:after {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 8px;
    position: absolute;
    right: -13px;
    top: 0px;
  }
  .serviceBox:hover .service-image > a > img {
    padding: 8px;
    border: 3px solid #7a4b94;
    transition: all 0.2s ease-in-out 0s;
  }
  .serviceBox:hover .service-content h3,
  .serviceBox:hover .read > a {
    color: #7a4b94;
  }

  