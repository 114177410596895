
.cstm-bg {
    background: url(http://localhost:3000/assets/img/welcome_background.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #e64e83;
    height: 200px;
    
}

.cstm-bg-dark {
    background: rgb(54,36,101, 0.6);
    border-radius: 15px;
    display: flex;
    align-items:center;
}

